.map-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.mapboxgl-ctrl-compass {
  display: none !important;
}
.mapboxgl-ctrl-bottom-right {
  transition: all .3s ease-out;
}
.mapboxgl-ctrl-bottom-right.slide-up {
  transform: translateY(-186px);
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  margin: 0 16px 30px 0 !important;
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
  border-radius: 8px !important;
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group button {
  width: 36px !important;
  height: 36px !important;
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group:first-of-type {
  border-radius: 50% !important;
}
.marker {
  background-image: url('assets/images/marker.png');
  background-size: cover;
  width: 30px;
  height: 50px;
  top: -23px;
  cursor: pointer;
}
