.root {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.root > input {
  height: 38px;
  padding: 2px 12px;
}
.root > textarea {
  padding: 12px;
}
.root > input,
.root > textarea {
  background-color: #f5f5f5;
  border-radius: 12px;
  border: none;
  font-family: 'Arial';
}
.root > input:focus,
.root > textarea:focus {
  outline: none;
}
.root > button {
  background-color: rgba(68, 109, 215);
  color: white;
  padding: 10px 8px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 12px;
  cursor: pointer;
}
.root > button:disabled {
  background-color: rgb(118 145 215);
  cursor: not-allowed;
}