.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -100;
  background-color: rgba(0,0,0,.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1000;
  opacity: 0;
  transition: none;
}
.root.open {
  z-index: 10000;
  opacity: 1;
  transition: opacity 0.3s;
}
.container {
  width: 100%;
  max-width: 300px;
  max-height: 100%;
  transition: all .2s ease-in-out;
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  font-size: 13px;
  border-radius: 10px;
}
.header {
  position: relative;
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header h3 {
  margin: 0;
}
.header button {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}
.content {
  padding: 0 24px 30px;
}
