.root {
  background-color: #e5fedf;
  font-size: 20px;
  padding: 16px;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 20px;
}
.root svg {
  color: #57b838;
}
