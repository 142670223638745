
.root {
  background-color: white;
  color: #989898;
  padding: 8px 10px 8px 18px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin: 60px auto 30px;
  border-radius: 14px;
  width: calc(100vw - 60px);
  max-width: 600px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 34px;
  box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
}
.root input {
  flex: 1;
  border: none;
  height: 40px;
  min-width: 150px;
}
.root input::placeholder {
  font-size: 15px;
}
.root input:focus {
  outline: none;
}
.root img {
  vertical-align: middle;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.root button {
  color: #989898;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
