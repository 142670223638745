.root {
  background-color: #436AD1;
  color: white;
  position: absolute;
  bottom: 30px;
  left: 16px;
  z-index: 2;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 20px;
  font-weight: 600;
  font-size: 13px;
  box-shadow: 0 7px 30px -10px rgb(150 170 180 / 50%);
  user-select: none;
  transition: all .3s ease-out;
}
.root.slideUp {
  transform: translateY(-186px);
}
.root svg {
  margin-right: 8px;
}
