.root {
  background: red;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: #f9f9f9;
}
.header > button {
  position: absolute;
  left: 0;
  cursor: pointer;
  background: none;
  border: none;
  padding: 8px;
  margin-left: 4px;
  font-size: 16px;
}

.listwrapper {
  margin-top: 48px;
  padding: 16px 0;
}
.listItem {
  width: 100vw;
  display: flex;
  gap: 10px;
  padding: 10px 8px;
  border: 1px solid #E7EDF8;
  margin-bottom: 14px;
}
.icon {
  background-color: rgb(236, 244, 255);
  font-size: 20px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 20px;
}
.name {
  color: #436AD1;
  font-size: 15px;
  margin: 0;
  margin-bottom: 6px;
}
.note {
  font-size: 13px;
  color: rgb(97, 97, 97);
}
.content {
  flex: 1;
  font-size: 13px;
}
.address {
  font-weight: 600;
}
.content p {
  margin: 0;
}
.separator {
  display: block;
  width: 100%;
  height: 2px;
  background: #E7EDF8;
  margin: 10px 0;
}

.grid {
  display: flex;
  gap: 10px;
}
.grid button {
  flex: 1;
  background-color: #EFF6FE;
  border: 2px solid #E7EDF8;
  padding: 6px 4px;
  border-radius: 30px;
  cursor: pointer;
}
.swipeAction {
  background: #ca142a;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
}