.root {
  padding: 4px 12px 40px;
}
.root h3 {
  text-align: center;
  font-weight: 600;
  color: #373737;
}
.root .grid {
  display: flex;
  gap: 10px;
}
.root .grid button {
  flex: 1;
  background-color: #EFF6FE;
  border: 2px solid #E7EDF8;
  padding: 10px 4px;
  border-radius: 30px;
  cursor: pointer;
}
.root .separator {
  display: block;
  width: 100%;
  height: 2px;
  background: #E7EDF8;
  margin: 20px 0;
}